// фиксирование шапки сайта
let lastScroll = 0;
const defaultOffSet = 800;
const headerWrapper = document.querySelector('.header-fix');
const scrollPosition = () =>
  window.pageYOffset || document.documentElement.scrollTop;
const containHide = () =>
  headerWrapper.classList.contains('header-fix_disabled');

window.addEventListener('scroll', () => {
  if (
    scrollPosition() > lastScroll &&
    containHide() &&
    scrollPosition() > defaultOffSet
  ) {
    headerWrapper.classList.remove('header-fix_disabled');
  } else if (
    scrollPosition() < lastScroll &&
    !containHide() &&
    scrollPosition() < defaultOffSet
  ) {
    headerWrapper.classList.add('header-fix_disabled');
  }
  lastScroll = scrollPosition();
});
