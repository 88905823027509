document.querySelectorAll('.pagination').forEach(pagination => {
    const target = document.querySelector(pagination.dataset.target);

    const items = pagination.querySelectorAll('.pagination__item');
    items.forEach(el => {

        el.addEventListener('click', function(e) {
            e.preventDefault();
            const page = el.dataset.page;

            target.querySelectorAll('.goods_show').forEach(goods => {
                goods.classList.remove('goods_show');
            });

            if (page === 'all') {
                target.querySelectorAll('.goods').forEach(el => {
                    el.classList.add('goods_show');
                });
            } else {
                target.querySelector('.goods_page_' + page)
                    .classList.add('goods_show');
            }

            items.forEach(item => {
                item.classList.remove('pagination__item_active');
            });

            el.classList.add('pagination__item_active');
        });
    });
});