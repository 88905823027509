quadrocopterButtons.forEach(i => {
  i.addEventListener('click', () => {
    popupQuadrocopter.classList.add('popup_active');

    // удаляю класс overlay
    overlay.classList.remove('overlay_hidden');
  });
});

buttonClosePopup.forEach(i => {
  i.addEventListener('click', () => {
    popups.forEach(p => {
      p.classList.remove('popup_active');

      // удаляю класс overlay
      overlay.classList.add('overlay_hidden');
    });
  });
});
