const galleryOverlay = document.querySelector('.popup__gallery-overlay');

for (let i = 0; i < obj.galleryHouse.length; i++) {
    const item = obj.galleryHouse[i];
    const itemGallery = document.querySelectorAll('.popup__gallery');

    item.addEventListener('click', () => {
        itemGallery[i].classList.add('popup_active');
        galleryOverlay.classList.add('_active')
    });
}

document.addEventListener('click', function (e) {
    let targetEl = e.target;
    if (targetEl.classList.contains('popup__gallery-overlay')) {
        targetEl.classList.remove('_active');
        document.querySelector('.popup__gallery.popup_active').classList.remove('popup_active');
    }

    if (targetEl.classList.contains('popup__button-close') && galleryOverlay.classList.contains('_active')) {
        galleryOverlay.classList.remove('_active');
    }
})


function galleryMainSliders(swiper, category) {
    const stoneSlides = document.querySelectorAll(`${swiper} .swiper-slide`); // 
    if (stoneSlides) {
        new Swiper(`${swiper}`, {
            preloadImages: false,
            loop: true,
            slidesPerView: 1,
            grabCursor: true,
            lazy: {
                loadOnTransitionStart: false,
                loadPrevNext: true
            },

            navigation: {
                prevEl: '.popup__slider-left',
                nextEl: '.popup__slider-right'
            },

            pagination: {
                el: `${swiper} .pagination`,
                clickable: true
            }
        })
    }

    const categorySlides = document.querySelectorAll(`${category} .popup__category-item`);
    if (categorySlides) {
        new Swiper(`${category}  .swiper`, {
            slidesPerView: 'auto',
        })
    }
}

galleryMainSliders('.swiper-stone', '.swiper-stone__categories')
galleryMainSliders('.swiper-brick', '.swiper-brick__categories')
galleryMainSliders('.swiper-mix', '.swiper-mix__categories') 