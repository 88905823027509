const anchors = document.querySelectorAll('a[href*="#"]');

// плавное перемещение к якорю
for (let anchor of anchors) {
  anchor.addEventListener('click', (e) => {
    e.preventDefault();
    const blockId = anchor.getAttribute('href');
    document.querySelector('' + blockId).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  });
}
