buttonOpenPopupPlus.addEventListener('click', () => {
  popupFeedback.classList.add('popup_active');
  overlay.classList.remove('overlay_hidden');
});

// кнопки в секции address
buttonOpenPopupFeedback.forEach(i => {
  i.addEventListener('click', () => {
    popupFeedback.classList.add('popup_active');
    overlay.classList.remove('overlay_hidden');
  });
});
