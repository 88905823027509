
// пробегаемся по всем кнопкам закрытия popup_ов
popupButtonClose.forEach(i => {
  i.addEventListener('click', () => {
    popups.forEach(p => {
      p.classList.remove('popup_active');

      // удаляю класс overlay
      overlay.classList.add('overlay_hidden');
    });
  });
});

// закрываю по клику на overlay
overlay.addEventListener('click', () => {
  popups.forEach(p => {
    p.classList.remove('popup_active');

    // удаляю класс overlay
    overlay.classList.add('overlay_hidden');
  });
});
