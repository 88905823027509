buttonOpenPopupVideo.addEventListener('click', () => {
  popupVideo.classList.add('popup__video-show_active');
  // добавляю класс overlay
  overlay.classList.remove('overlay_hidden');
});

popupButtonClose.forEach(i => {
  i.addEventListener('click', () => {
    popupVideo.classList.remove('popup__video-show_active');
    // удаляю класс overlay
    overlay.classList.add('overlay_hidden');
  });
});

overlay.addEventListener('click', () => {
  // если открыт popup видео, тогда по клику на overlay закрыть его
  if(popupVideo.classList.contains('popup__video-show_active')) {
    // удаляю класс overlay
    popupVideo.classList.remove('popup__video-show_active');
  }
})
