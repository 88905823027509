const sliderLine = document.querySelector('.slider__line');
const slider = document.querySelector('.slider');
const slide = document.querySelectorAll('.slide');
const button = document.querySelector('#button');
const buttonTwoOne = document.querySelector('#button__two_one');
const container = document.querySelector('#container');
const containerWidth = container.offsetWidth;
let count = 0;
let width;

// адаптировать слайдер под ширину контейнера
const init = () => {
  width = containerWidth;

  sliderLine.style.width = width * (slide.length + (slide.length - 1)) + 'px';

  slide.forEach(i => {
    i.style.height = 'auto';
    i.style.minWidth = slider.offsetWidth + 'px';
    i.style.margin = `0 ${slider.offsetWidth}px 0 0`
  });
}

window.addEventListener('resize', init);

init();

// перейти к след. слайду
const playSlide = () => {
  count += slider.offsetWidth * 2;

  if (count > (width * 3)) {
    button.style.display = 'none';
  }
  sliderLine.style.left = -count + 'px';
}

button.addEventListener('click', playSlide);

buttonTwoOne.addEventListener('click', () => {
  count = 0;
  sliderLine.style.left = count + 'px';
});
