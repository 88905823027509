const hamburger = document.querySelector('.first__hamburger');
const menu = document.querySelector('.menu-mobile');
const firstBackgroundImage = document.querySelector('.first__bg');
const firstHamburger = document.querySelector('.first__hamburger');
const body = document.body;


// мобильное меню
hamburger.addEventListener('click', () => {
    // меняем гамбургер
    hamburger.classList.toggle('first__hamburger_active');


    body.classList.toggle('_hide-cart');

    // открываем - закрываем меню
    menu.classList.toggle('menu-mobile_hidden');
    firstBackgroundImage.classList.toggle('first__bg_fixed');
});

// закрыть мобильное меню по клику на пункт меню
const menuItem = document.querySelectorAll('.menu__mobile-item');

menuItem.forEach(i => {
    i.addEventListener('click', () => {
        // открываем - закрываем меню
        menu.classList.toggle('menu-mobile_hidden');
        firstBackgroundImage.classList.toggle('first__bg_fixed');
        firstHamburger.classList.remove('first__hamburger_active');

        body.classList.toggle('_hide-cart');
    })
})
