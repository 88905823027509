
const body = document.body;
const cards = document.querySelectorAll('.card');
const cart = document.querySelector('.cart');
const cartCount = document.querySelector('.open-cart__count input');
const cartMain = document.querySelector('.cart__main');
const cartForm = document.querySelector('.popup__cards');
const cartBody = document.querySelector('.cart__body');
let cartStatus = 'empty';

if (cards) {
    const localCards = JSON.parse(localStorage.getItem('products'));

    if (localCards && Object.keys(localCards).length != 0) {
        cartStatus = 'in';
        changeCartStatus(cartStatus);
    }
    else {
        cartStatus = 'empty';
        changeCartStatus(cartStatus);
    }

    for (let i = 0; i < cards.length; i++) {

        cards[i].setAttribute('id', `product-${i + 1}`);
        const id = cards[i].getAttribute('id');

        if (localCards && localCards[id]) {
            const button = cards[i].querySelector('.goods__button');
            button.textContent = button.dataset.in;
            button.disabled = true;
            cartCount.value = Object.keys(localCards).length

            const img = cards[i].querySelector('img').dataset.src;
            const cname = cards[i].querySelector('.goods__title div').textContent;
            const price = cards[i].querySelector('.goods__package').textContent;
            let cartItem =
                `
                    <div class="cart__item" data-id="${id}">
                        <img src="${img}" alt="">
                        <div class="cart__item-content">
                            <div class="cart__item-content-top">
                                <h4 class="cart__item-name">${cname}</h4>
                                <button class="cart__item-remove">×</button>
                            </div>
                            <div class="cart__item-bottom">
                                <div class="cart__item-price">${price}</div>
                                <div class="cart__item-quantity quantity">
                                    <button class="minus">-</button>
                                    <input type="text" class="quantity-input" value="1">
                                    <button class="plus">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            cartMain.insertAdjacentHTML('afterbegin', cartItem);
        }
    }
}

function changeCartStatus(status) {
    const button = document.querySelector('.cart__send');
    const emptyButton = document.querySelector('.cart__empty');
    if (button) {
        if (status == 'empty') {
            button.textContent = 'Корзина пуста';
            button.classList.remove('cart__send')
            button.classList.add('cart__empty')
        }

        else {
            button.textContent = 'Оформить заказ';
            button.classList.remove('cart__empty')
            button.classList.add('cart__send')
        }
    }

    if (emptyButton) {
        if (status == 'empty') {
            emptyButton.textContent = 'Корзина пуста';
            emptyButton.classList.remove('cart__send')
            emptyButton.classList.add('cart__empty')
        }

        else {
            emptyButton.textContent = 'Оформить заказ';
            emptyButton.classList.remove('cart__empty')
            emptyButton.classList.add('cart__send')
        }
    }

}


document.addEventListener('click', function (e) {
    let targetEl = e.target;
    let data = {};

    if (targetEl.classList.contains('goods__button')) {

        const card = targetEl.closest('.card');
        const id = card.getAttribute('id');

        if (cartCount && targetEl.disabled == false) {

            const img = card.querySelector('img').dataset.src;
            const cname = card.querySelector('.goods__title div').textContent;
            const price = card.querySelector('.goods__package').textContent;
            let cartItem =
                `
                    <div class="cart__item" data-id="${id}">
                        <img src="${img}" alt="">
                        <div class="cart__item-content">
                            <div class="cart__item-content-top">
                                <h4 class="cart__item-name">${cname}</h4>
                                <button class="cart__item-remove">×</button>
                            </div>
                            <div class="cart__item-bottom">
                                <div class="cart__item-price">${price}</div>
                                <div class="cart__item-quantity quantity">
                                    <button class="minus">-</button>
                                    <input type="text" class="quantity-input" value="1">
                                    <button class="plus">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            cartMain.insertAdjacentHTML('afterbegin', cartItem);

            cartCount.value++;
        }

        targetEl.textContent = targetEl.dataset.in;
        targetEl.disabled = true;

        data[id] = id;
        if (localStorage.getItem('products')) {
            const data2 = JSON.parse(localStorage.getItem('products'));
            data2[id] = id;

            localStorage.setItem('products', JSON.stringify(data2));
        }
        else {
            localStorage.setItem('products', JSON.stringify(data));
        }

        changeCartStatus('in');
    }

    if (targetEl.classList.contains('cart__item-remove')) {
        const cartItem = targetEl.closest('.cart__item');
        const id = cartItem.dataset.id;

        const card = document.querySelector(`.card#${id}`);
        const button = card.querySelector('.goods__button');

        const localCards = JSON.parse(localStorage.getItem('products'));
        delete localCards[`${id}`];
        localStorage.setItem('products', JSON.stringify(localCards));

        button.textContent = button.dataset.out;
        button.disabled = false;

        if (cartCount.value > 0) {
            cartCount.value--;
        }

        if (Object.keys(localCards).length != 0) {
            cartStatus = 'in';
            changeCartStatus(cartStatus);
        }
        else {
            cartStatus = 'empty';
            changeCartStatus(cartStatus);
        }

        cartItem.remove();

    }

    if (targetEl.classList.contains('open-cart')) {
        cart.classList.add('_open');
        body.classList.add('_noscroll');
    }

    if (targetEl.classList.contains('cart__close') || targetEl.classList.contains('cart__overlay')) {
        cart.classList.remove('_open');
        body.classList.remove('_noscroll');
    }

    if (targetEl.classList.contains('cart__send')) {
        targetEl.disabled = true;
        cartForm.classList.add('popup_active');
        cartBody.classList.add('_hide');
        cart.classList.add('_hide');

        const select = document.querySelector('#cart-select');
        const productsInCart = JSON.parse(localStorage.getItem('products'));

        if (select.querySelector('option')) {
            select.querySelectorAll('option').forEach(opt => {
                opt.remove();
            });
        }

        for (const product in productsInCart) {
            const prod = document.querySelector(`#${product}`);
            const productName = prod.querySelector('.goods__title div').textContent;

            const prodInCart = cart.querySelector(`[data-id="${product}"]`);
            const prodCount = prodInCart.querySelector('input').value;

            const option = `<option selected value="Название: ${productName}, Количество: ${prodCount}"></option>`;
            select.insertAdjacentHTML('beforeend', option);
        }
    }

    if (targetEl.classList.contains('overlay')) {
        if (cart.classList.contains('_open')) {
            document.querySelector('.cart__send').disabled = false;
            cartForm.classList.remove('popup_active');
            cartBody.classList.remove('_hide');
            cart.classList.remove('_hide');
        }
    }

    if (targetEl.classList.contains('popup__button-close')) {
        if (targetEl.closest('.popup__cards') || targetEl.closest('.popup__error') || targetEl.closest('.popup__thanks')) {
            document.querySelector('.cart__send').disabled = false;
            cartForm.classList.remove('popup_active');
            cartBody.classList.remove('_hide');
            cart.classList.remove('_hide');
        }
    }

    if (targetEl.classList.contains('minus')) {
        let input = targetEl.closest('.quantity').querySelector('input');
        if (+input.value > 1) {
            +input.value--;
        }
    }

    if (targetEl.classList.contains('plus')) {
        let input = targetEl.closest('.quantity').querySelector('input');
        +input.value++;
    }
})

document.addEventListener('input', function (e) {
    let targetEl = e.target;

    if (targetEl.classList.contains('quantity-input')) {
        targetEl.value = targetEl.value.replace(/\D/gi, '')
        targetEl.value = targetEl.value.replace(/^[0]/gi, '')

        if (targetEl.value == '') {
            targetEl.value = 1
        }
    }
})