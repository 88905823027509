const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const forms = document.querySelectorAll('.js-mail-form');

forms.forEach(form => {
    form.addEventListener('submit', async function (e) {
        e.preventDefault();

        if (validate(this)) {
            const self = this;
            let response = await fetch('/wp-content/themes/hauberg/assets/php/send.php', {
                method: 'post',
                body: new FormData(this),
            })

            if (response.ok) {
                popups.forEach(el => el.classList.remove('popup_active'));
                overlay.classList.remove('overlay_hidden');
                popupThanks.classList.add('popup_active');

                self.reset();
            }
            else {
                ajaxFormError();
            }
        }
    });

    form.querySelectorAll('.form__input').forEach(el => {
        el.addEventListener('input', function (e) {
            const buttonElement = form.querySelector('.form__btn');

            if (!buttonElement) {
                return;
            }

            if (getValidationResult(form)) {
                buttonElement.classList.remove('form__btn_inactive');
            } else {
                buttonElement.classList.add('form__btn_inactive');
            }
        });
    });
});

function ajaxFormError(response) {
    popups.forEach(el => el.classList.remove('popup_active'));

    popupError.classList.add('popup_active');
    overlay.classList.remove('overlay_hidden');
}

class Validation {
    constructor(form) {
        this.valid = true;
        this.form = form;
        this.modifyDOM = true;
    }

    run() {
        const phoneFields = this.form.querySelectorAll('.form__input_phone');
        phoneFields.forEach(el => {
            this.validateField(el, getRealInput(el).inputmask.isComplete());
        });

        const emailFields = this.form.querySelectorAll('.form__input_email');
        emailFields.forEach(el => {
            this.validateField(el, validateEmail(getRealInput(el).value));
        });

        const textFields = this.form.querySelectorAll('.form__input:not(.form__input_phone, .form__input_email), .form__textarea');
        textFields.forEach(el => {
            this.validateField(el, getRealInput(el).value !== '');
        });

        const agree = this.form.querySelector('.form__policy');
        this.validateField(this.form.querySelector('.form__label-policy'), agree.checked);


    }

    validateField(field, isValid) {
        if (field === document.querySelector('#input-square')) {
            return;
        }

        if (isValid) {
            if (this.modifyDOM) {
                field.classList.remove('form__input_error');
            }
        } else {
            if (this.modifyDOM) {
                field.classList.add('form__input_error');
            }
            this.valid = false;
        }
    }
}

function getRealInput(el) {
    if (el.tagName !== 'INPUT' && el.tagName !== 'TEXTAREA') {
        return el.querySelector('input, textarea');
    } else {
        return el;
    }
}

function validate(form) {
    const validation = new Validation(form);

    validation.run();
    return validation.valid;
}

function getValidationResult(form) {
    const validation = new Validation(form);
    validation.modifyDOM = false;

    validation.run();
    return validation.valid;

}