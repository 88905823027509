// общие константы и переменные
const body = document.querySelector('#body');
// нахожу кнопки открытия popup-quadrocopter
const quadrocopterButtons = document.querySelectorAll('.quadrocopter');
// нахожу popup quadrocopter
const popupQuadrocopter = document.querySelector('.popup__quadrocopter');
// нахожу нижнюю кнопку закрытия popup_ов
const buttonClosePopup = document.querySelectorAll('.popup__close-button');
// нахожу крестик-кнопку закрытия popup_ов
const popupButtonClose = document.querySelectorAll('.popup__button-close');
// нахожу все popup_ы
const popups = document.querySelectorAll('.popup');
// нахожу все кнопки для открытия popup_ов из карточек
const goodsOpenButtonPopup = document.querySelectorAll('.goods__button');
// нахожу overlay
const overlay = document.querySelector('.overlay');

// нахожу popup карточек
const popupCards = document.querySelector('.popup__cards');
// найти кнопку открытия popup с видео в первой секции
const buttonOpenPopupVideo = document.querySelector('.first__video');
// найти popup с видео в первой секции
const popupVideo = document.querySelector('.popup__video-show');
// найти кнопку открытия popup политики конфиденциальности
const buttonsPolicy = document.querySelectorAll('.policy');
// найти popup политики конфиденциальности
const popupPolicy = document.querySelector('.popup__policy');
// найти кнопку 1-го сертификата
const certificate1 = document.querySelector('#certificate-1');
// найти popup 1-го сертификата
const onePopupCertificate = document.querySelector('.popup__certificate_one');
// найти кнопку 2-го сертификата
const certificate2 = document.querySelector('#certificate-2');
// найти popup 1-го сертификата
const twoPopupCertificate = document.querySelector('.popup__certificate_two');
// найти кнопку открытия формы обратной связи из section plus
const buttonOpenPopupPlus = document.querySelector('.plus__button');
// найти popup feedback
const popupFeedback = document.querySelector('.popup__feedback');
// найти все кнопки открытия popup feedback в секции address
const buttonOpenPopupFeedback = document.querySelectorAll('.address__button');

const popupThanks = document.querySelector('.popup__thanks');
const popupError = document.querySelector('.popup__error');

// фото из галереи
const arrObjects = document.querySelectorAll('.gallery__house');
const overlayGallery = document.querySelector('.gallery__overlay');

// obj
const obj = {
  galleryHouse: document.querySelectorAll('.gallery__house'),
  galleryOverlay: document.querySelector('.gallery__overlay'),
  popupGallery: document.querySelector('.popup__gallery'),
}

// gallery photo
const popupGalleryImages = {
  stone: {
    quartzite: [
      {
        src: './../img/gallery/kirpich.jpg',
        alt: 'Дом из кирпича'
      },
    ],
    slate: [
      {
        src: './../img/gallery/kirpich.jpg',
        alt: 'Дом из кирпича'
      },
    ],
    travertine: [
      {
        src: './../img/gallery/kirpich.jpg',
        alt: 'Дом из кирпича'
      },
    ]
  },
  brick: {
    english: [
      {
        src: './../img/gallery/kirpich.jpg',
        alt: 'Дом из кирпича'
      },
    ],
    antique: [
      {
        src: './../img/gallery/kirpich.jpg',
        alt: 'Дом из кирпича'
      },
    ],
    bavarian: [
      {
        src: './../img/gallery/kirpich.jpg',
        alt: 'Дом из кирпича'
      },
    ],
    belgian: [
      {
        src: './../img/gallery/kirpich.jpg',
        alt: 'Дом из кирпича'
      },
    ],
    gothic: [],
    catalan: [],
    red: [],
    marble: [],
    burnt: [],
    sandy: [],
    greyBeige: [

    ],
    terracotta: [],
    scottish: []
  }
};
