const activeTitleSectionInHeader = document.querySelector('.crumbs__item_active');
const activeTitleSectionInHeaderMobile = document.querySelector('.menu-title_active');
const first = document.querySelector('.first');
const catalog = document.querySelector('.catalog');
const application = document.querySelector('.application');
const video = document.querySelector('.video');
const plus = document.querySelector('.plus');
const gallery = document.querySelector('.gallery');
const certificate = document.querySelector('.certificate');
const contacts = document.querySelector('.contacts');

// section first
const setFirstTitle = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeTitleSectionInHeader.textContent = '';
      activeTitleSectionInHeaderMobile.textContent = '';
      activeTitleSectionInHeader.href = '#first';
    }
  })
}, {
  threshold: 0
});

// section catalog
const setCatalogTitle = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeTitleSectionInHeader.textContent = 'Каталог продукции';
      activeTitleSectionInHeaderMobile.textContent = 'Каталог продукции';
      activeTitleSectionInHeader.href = '#catalog';
    }
  })
}, {
  threshold: 0.3
});

// section application
const setApplicationTitle = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeTitleSectionInHeader.textContent = 'Заявка на замер';
      activeTitleSectionInHeaderMobile.textContent = 'Заявка на замер';
      activeTitleSectionInHeader.href = '#application';
    }
  })
}, {
  threshold: 0.5
});

// section video
const setVideoTitle = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeTitleSectionInHeader.textContent = 'Видео обзор';
      activeTitleSectionInHeaderMobile.textContent = 'Видео обзор';
      activeTitleSectionInHeader.href = '#video';
    }
  })
}, {
  threshold: 0.5
});

// section plus
const setPlusTitle = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeTitleSectionInHeader.textContent = 'Подводные камни';
      activeTitleSectionInHeaderMobile.textContent = 'Подводные камни';
      activeTitleSectionInHeader.href = '#plus';
    }
  })
}, {
  threshold: 0.5
});

// section gallery
const setGalleryTitle = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeTitleSectionInHeader.textContent = 'Галерея построенных домов';
      activeTitleSectionInHeaderMobile.textContent = 'Галерея построенных домов';
      activeTitleSectionInHeader.href = '#gallery';
    }
  })
}, {
  threshold: 0.5
});

// section certificate
const setCertificateTitle = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeTitleSectionInHeader.textContent = 'Сертификаты';
      activeTitleSectionInHeaderMobile.textContent = 'Сертификаты';
      activeTitleSectionInHeader.href = '#certificate';
    }
  })
}, {
  threshold: 0.5
});

// section contacts
const setContactsTitle = new IntersectionObserver(function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      activeTitleSectionInHeader.textContent = 'Контакты';
      activeTitleSectionInHeaderMobile.textContent = 'Контакты';
      activeTitleSectionInHeader.href = '#contacts';
    }
  })
}, {
  threshold: 0
});

setFirstTitle.observe(first);
setCatalogTitle.observe(catalog);
setApplicationTitle.observe(application);
setVideoTitle.observe(video);
setPlusTitle.observe(plus);
setGalleryTitle.observe(gallery);
setCertificateTitle.observe(certificate);
setContactsTitle.observe(contacts);
