// стрелка вверх
const sttElem = document.querySelector('.stt');
const screenHeight = window.innerHeight;

const sttScroll = function sttScroll() {
  document.addEventListener('scroll', function (e) {
    if (screenHeight <= window.scrollY) {
      sttElem.classList.add('stt__active');
    } else if (e.target.scrollingElement.scrollTop <= screenHeight) {
      sttElem.classList.remove('stt__active');
      sttElem.style.pointerEvents = 'auto';
    }
  });
};

const sttClick = function sttClick() {
  sttElem.addEventListener('click', function () {
    const docHeight = window.scrollY;
    let progress = 0;
    let position = docHeight;
    const speed = 5;

    sttElem.style.pointerEvents = 'none';

    const sttAnim = function sttAnim() {
      progress += 1;
      position -= progress * speed;
      window.scrollTo(0, position);

      if (position > 0) {
        requestAnimationFrame(sttAnim);
      }
    };

    requestAnimationFrame(sttAnim);
  });
};

const sttFunc = function sttFunc() {
  sttScroll();
  sttClick();
};

document.addEventListener('DOMContentLoaded', sttFunc);
